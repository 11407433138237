import Popover from '@/components/Molecules/Popover/Popover'
import SidebarWorkspaceDropdown from '@/components/v2/Sidebar/SidebarWorkspaceDropdown'
import { usePlatformVersion } from '@/utils/lib/platform-version'
import { segmentTrackCallback } from '@/utils/segment'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  QureosLogoSquare,
  qureosMiniIcon,
  VfsServiceLogo
} from '@qureos/assets'
import { SegmentEventType } from '@qureos/types'
import { Drawer, Image } from 'antd'
import QureosLogoBlue from 'assets/logos/QureosLogoBlue'
import clsx from 'clsx'
import { map } from 'lodash'
import { useRouter } from 'next/router'
import { FC, ReactNode, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { apprenticeSidebarOpen, user as userAtom } from 'src/atoms'
import { WorkspaceContext } from 'src/context/workspace/workspace.context'
import { User, UserRole } from 'src/types/shared/graphql'
import { ShouldRender } from '../misc'
import DefaultSideMenu from './DefaultSideMenu'
import DefaultSideMenuV2 from './DefaultSideMenuV2'
interface SidebarProps {
  bgColor?: string
  role: UserRole
  children?: ReactNode
}

const DefaultSidebar: FC<SidebarProps> = ({ bgColor, role, children }) => {
  const router = useRouter()
  const user = useRecoilValue<User>(userAtom)
  const { t: Label } = useTranslation('Sidebar')
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const isSidebarVisible = useRecoilValue<boolean>(apprenticeSidebarOpen)

  const platformVersion = usePlatformVersion()

  const showOverlaySidebar = useMemo(() => {
    return role === UserRole.Professional && platformVersion === 'v2'
  }, [role, platformVersion])

  const backgroundColorClass = bgColor ?? 'bg-qureosPrimary'
  const sidebarWidth = showOverlaySidebar ? 'w-16' : 'w-60 xl:w-64'

  const { workspaces, activeWorkspace, setActiveWorkspace } =
    useContext(WorkspaceContext)

  const getRedirectUrlUponWorkspaceChange = (id: string) => {
    const currentUrl = new URL(window.location.href)
    const pathSegments = currentUrl.pathname.split('/')

    // Find the index of the 'workspace' segment
    const workspaceIndex =
      pathSegments.findIndex(segment => segment === 'workspace') + 1

    // Check if the workspaceId is present and replace it
    if (workspaceIndex > 0 && workspaceIndex < pathSegments.length) {
      pathSegments[workspaceIndex] = id
    }
    currentUrl.pathname = pathSegments.join('/')
    return currentUrl.toString()
  }

  const SidebarContent: FC<{ width: string }> = () => (
    <div
      {...(!!showOverlaySidebar && {
        onMouseEnter: () => setIsDrawerOpen(true),
        onMouseLeave: () => setIsDrawerOpen(false)
      })}
      className={clsx(
        'h-full fixed overflow-hidden sidebarStyle z-[200] bg-white border-r border-[#E5E7EB] w-16',
        isSidebarVisible ? '' : 'closed',
        showOverlaySidebar ? 'w-16' : 'w-60 xl:w-64'
      )}
    >
      <div className="flex flex-col w-full h-full overflow-auto">
        <div
          className={clsx('w-full h-16 flex justify-between p-2', {
            'px-4': !showOverlaySidebar
          })}
        >
          <div
            className={clsx('flex items-center', {
              'mx-auto': showOverlaySidebar
            })}
          >
            {role === UserRole.Professional ? (
              user?.defaultCompany?.isVfs ? (
                <Image
                  src={VfsServiceLogo}
                  className="cursor-pointer w-36"
                  preview={false}
                />
              ) : showOverlaySidebar ? (
                <Image
                  preview={false}
                  src={qureosMiniIcon}
                  className="w-10 h-10 cursor-pointer"
                />
              ) : (
                <QureosLogoSquare
                  className="cursor-pointer fill-qureosSecondary my-6 w-[106px]"
                  onClick={() => router.push('/')}
                />
              )
            ) : role === UserRole.Student ? (
              <QureosLogoBlue
                className="cursor-pointer fill-qureosSecondary my-6 w-[106px]"
                onClick={() => router.push('/')}
              />
            ) : (
              <QureosLogoSquare
                className="cursor-pointer fill-qureosSecondary my-6 w-[106px]"
                onClick={() => router.push('/')}
              />
            )}
          </div>
        </div>
        {children}
        {showOverlaySidebar ? (
          <DefaultSideMenuV2
            showLabel
            role={role}
            user={user}
            isDrawerOpen={isDrawerOpen}
          />
        ) : (
          <DefaultSideMenu showLabel role={role} user={user} />
        )}
      </div>
    </div>
  )

  const sidebarBaseClasses = 'relative flex-none transition-all'

  return (
    <>
      <div
        className={clsx(
          'hidden md:inline border-r border-[#E5E7EB]',
          sidebarWidth,
          sidebarBaseClasses,
          backgroundColorClass
        )}
      >
        <SidebarContent width={sidebarWidth} />
      </div>
      <div
        className={clsx(
          'md:hidden overflow-hidden',
          sidebarBaseClasses,
          backgroundColorClass,
          isSidebarVisible ? 'w-24' : 'w-0'
        )}
      >
        <SidebarContent width={isSidebarVisible ? 'w-24' : 'w-0'} />
      </div>

      <ShouldRender check={showOverlaySidebar}>
        <Drawer
          key="left"
          placement="left"
          closable={false}
          visible={isDrawerOpen}
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ maxWidth: '256px' }}
        >
          <div
            className="flex flex-col justify-between w-full h-screen"
            onMouseEnter={() => setIsDrawerOpen(true)}
            onMouseLeave={() => {
              setIsDrawerOpen(false)
            }}
          >
            <div className="flex flex-row items-center justify-between w-full gap-2 p-3">
              <Image
                preview={false}
                src={qureosMiniIcon}
                className="w-10 h-10 cursor-pointer"
                wrapperClassName="min-w-fit"
              />
              <div className="flex flex-col line-clamp-2">
                <span className="text-lg font-semibold leading-5 text-qureosPrimary line-clamp-1">
                  {activeWorkspace?.title}
                </span>
                <span className="text-xs">{Label('SWITCH_WORKSPACE')}</span>
              </div>

              {isDrawerOpen && (
                <Popover
                  trigger="click"
                  overlayClassName="sidemenu-v2-workspace-dropdown"
                  placement="bottomRight"
                  content={
                    <SidebarWorkspaceDropdown
                      activeWorkspace={activeWorkspace?._id}
                      workspaceItems={map(workspaces, workspace => {
                        return {
                          id: workspace._id,
                          title: workspace.title,
                          link: getRedirectUrlUponWorkspaceChange(
                            workspace?._id
                          ),
                          onClick: () => {
                            setActiveWorkspace(workspace)
                            segmentTrackCallback(
                              SegmentEventType.WORKSPACE_SELECTED_FROM_SIDEBAR,
                              {
                                userId: user?._id,
                                companyName: user?.defaultCompany?.name,
                                companyId: user?.defaultCompany?._id,
                                clientName: `${user?.firstName} ${user?.lastName}`,
                                email: user?.email,
                                workspaceId: workspace._id,
                                workspaceName: workspace.title
                              }
                            )
                          }
                        }
                      })}
                    />
                  }
                >
                  <ChevronDownIcon className="w-4 h-4 cursor-pointer min-w-fit" />
                </Popover>
              )}
            </div>
            <DefaultSideMenuV2
              showLabel
              role={role}
              user={user}
              isDrawerOpen={isDrawerOpen}
            />
          </div>
        </Drawer>
      </ShouldRender>
    </>
  )
}

export default DefaultSidebar
