import Menu from '@/components/Organisms/Dropdown/Menu'
import clsx from 'clsx'
import Link from 'next/link'
import { FC } from 'react'

interface Props {
  activeWorkspace?: string
  workspaceItems: {
    id: string
    title: string
    link: string
    onClick: () => void
  }[]
}

const SidebarWorkspaceDropdown: FC<Props> = ({
  workspaceItems,
  activeWorkspace
}) => (
  <Menu>
    {workspaceItems.map(workspaceItem => (
      <Menu.Item key={workspaceItem.id}>
        <Link href={workspaceItem.link} passHref>
          <a
            className={clsx(
              'hover:text-qureosPrimary',
              activeWorkspace === workspaceItem.id && 'text-qureosPrimary'
            )}
            onClick={workspaceItem.onClick}
            role="button"
          >
            {workspaceItem.title}
          </a>
        </Link>
      </Menu.Item>
    ))}
  </Menu>
)

export default SidebarWorkspaceDropdown
