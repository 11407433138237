import { Button } from '@/components/shared/basic/Buttons'
import { toUnix } from '@/utils/date-helper'
import { segmentTrackCallback } from '@/utils/segment'
import { formatErrorObject } from '@/utils/static-helpers/formatError'
import { getUserInitials } from '@/utils/static-helpers/initials'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { ArrowRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { notify } from '@qureos/lib'
import { ApprenticeProfile, SegmentEventType } from '@qureos/types'
import { Avatar, Button as ButtonAntd, Dropdown, Menu } from 'antd'
import clsx from 'clsx'
import { isEmpty, replace } from 'lodash'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import Link from 'next/link'
import { useRouter } from 'next/router'
import pluralize from 'pluralize'
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { user as userAtom } from 'src/atoms'
import { subscription } from 'src/atoms/subscription'
import { updateProfile as updateProfileAtom } from 'src/atoms/user'
import { useLogout } from 'src/hooks'
import { updateApprenticesSettings } from 'src/services/apprentices'
import pusher from 'src/services/pusher'
import {
  ApprenticeResumeStatus,
  RESUME_PROGRESS
} from 'src/types/shared/apprentice'
import { UserRole } from 'src/types/shared/graphql'
import { SubscriptionStatus } from 'src/types/shared/subscription'
import { DEFAULT_USER_SIGNUP_CODE } from 'statics/global'
import {
  PageInterface,
  SidebarProps,
  SidebarSectionsEnum
} from 'statics/interface'
import { ShouldRender } from '../misc'
import { Switch } from '../misc/Switch'
import { useJobStats } from 'src/hooks/data/useJobStats'
import { isActive, MENU } from '@/utils/static-helpers/sidebar-helper'
import { CreditsContext } from 'src/context/corporate/credits.context'

const ApprenticeSideMenu: FunctionComponent<SidebarProps> = ({
  showLabel,
  role,
  signupCode,
  user,
  isMobile = false
}) => {
  const { usage } = useContext(CreditsContext)
  const [, setUpdate] = useRecoilState<boolean>(updateProfileAtom)
  const profile = useRecoilValue<ApprenticeProfile>(userAtom)
  const [showCvProgressCard, setShowCvProgressCard] = useState<boolean>(
    (profile?.professionalDetails?.resumes?.[0]?.status &&
      !profile?.meta?.profileSeenAt) ||
      false
  )
  const [resumeStatus, setResumeStatus] = useState<string>(
    profile?.professionalDetails?.resumes?.[0]?.status ||
      ApprenticeResumeStatus.COMPLETE
  )
  const isVfsServiceEnable = profile?.defaultCompany?.isVfs || false
  const { t: label } = useTranslation('ApprenticeProfile')

  const showCard = useMemo(
    () =>
      !!(
        profile?.preferences?.workStyle?.length &&
        profile?.photo &&
        profile?.settings?.profileHeadline &&
        profile?.nationality &&
        profile?.linkedIn &&
        profile?.professionalDetails?.educationHistory?.length &&
        profile?.professionalDetails?.educationStatus &&
        profile?.location &&
        profile?.phone &&
        profile?.preferences?.jobLocations?.length &&
        profile?.preferences?.personality &&
        profile?.preferences?.companyValues
      ),
    [profile]
  )
  const subscriptionStatus = useRecoilValue(subscription)
  const {
    jobStats: { uniqueJobCountWithUpdates }
  } = useJobStats()
  const router = useRouter()
  const logout = useLogout()

  if (!role) {
    return null
  }
  const pages = filter(MENU(isVfsServiceEnable), item =>
    item.permissions.includes(role)
  )

  const labelClass: string[] = ['text-sm', 'font-base', 'normal-case']

  const filterPages = (page: PageInterface) =>
    !page.restriction.includes(signupCode || DEFAULT_USER_SIGNUP_CODE)

  const handleRoleChange = async (role: boolean) => {
    try {
      await updateApprenticesSettings({
        openForRoles: role
      })
      setUpdate(true)
      notify({ message: 'Role updated successfully!', type: 'success' })
    } catch (error) {
      const message = formatErrorObject(error)
      notify({ message: message, type: 'error' })
    }
  }

  const filterBySubscription = (page: PageInterface) => {
    const { Subscribed, Never_Subscribed, Expired, Trial, Cancelled } =
      SubscriptionStatus
    let show = true
    if (
      [Subscribed, Expired, Trial, Cancelled].includes(subscriptionStatus) &&
      page.restriction.includes('not-subscribed')
    ) {
      show = false
    }

    if (
      [Never_Subscribed].includes(subscriptionStatus) &&
      page.restriction.includes('subscribed')
    ) {
      show = false
    }
    if (page.restriction.includes('vfs-service') && !isVfsServiceEnable) {
      show = false
    }

    /* hiding the fyp tab for all users */
    if (page.restriction.includes('for-you')) {
      show = false
    }
    return show
  }

  const onLogout = () => {
    try {
      logout()
      notify({ message: "You're logged out!", type: 'success' })
      switch (role) {
        case UserRole.Professional:
          router.replace('/corporate/login')
          break
        case UserRole.Student:
        case UserRole.Admin:
          segmentTrackCallback(SegmentEventType.LOGOUT_BUTTON, {
            userId: user._id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            logoutDateTime: new Date()
          })
          router.replace('/')
          break
        case UserRole.Mentor:
          router.replace('/mentor/signin')
          break
        default:
          router.replace('/')
      }
    } catch (error) {
      notify({ message: error.message, type: 'error' })
    }
  }

  let menuItems = [
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Terms and Conditions', path: '/terms-conditions' }
  ]

  if (user?.role === UserRole.Student) {
    menuItems = [
      { label: 'Manage Plan', path: '/manage-plan' },
      { label: 'Settings', path: '/settings' },
      ...menuItems
    ]
  }

  const menu = (
    <Menu className="w-[224px] text-gray-900 px-1 rounded-md ring-1 ring-black/5 shadow-lg  mt-2">
      <ShouldRender check={user.role === UserRole.Student}>
        <Menu.Item className="py-2 transition-colors border-b-2 border-gray-100 hover:bg-transparent">
          <div className="flex" onClick={event => event.stopPropagation()}>
            <div className="mt-2">
              <Switch
                checked={user?.settings?.openForRoles}
                onChange={value => {
                  handleRoleChange(value)
                }}
              />
            </div>
            <div className="ml-4 font-medium text-gray-700 ">
              Open to roles
              <div className="text-xs font-normal text-gray-500 whitespace-normal max-w-20 ">
                <p> Activate to let employers know</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      </ShouldRender>

      {!isVfsServiceEnable && (
        <Menu.Item className="py-0 transition-colors border-b-2 border-gray-100 hover:bg-transparent">
          {user.role === UserRole.Professional ? (
            <Link href="/settings">
              <div className="my-3">
                <p className="font-medium w-[180px] truncate">Settings</p>
              </div>
            </Link>
          ) : (
            <Link href="/profile">
              <div className="my-3">
                <p className="font-medium w-[180px] truncate">View Profile</p>
              </div>
            </Link>
          )}
        </Menu.Item>
      )}
      <div className="flex flex-col gap-3 my-3">
        {menuItems.map((item, i) => (
          <Menu.Item
            key={i}
            className="py-0 transition-colors hover:text-qureosPrimary hover:bg-transparent"
          >
            <Link href={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </div>
      <Menu.Item
        onClick={onLogout}
        className="py-0 transition-colors border-t-2 border-gray-100 hover:text-qureosPrimary hover:bg-transparent"
      >
        <div className="my-3">
          <p>Sign out</p>
        </div>
      </Menu.Item>
    </Menu>
  )

  const handleClick = ({ page }): string => {
    if (page.pathname === 'community') {
      segmentTrackCallback(SegmentEventType.COMMUNITY_BUTTON, {
        id: user._id,
        email: user.email,
        firstName: user.firstName,
        date: new Date()
      })
    }
    return page.link
  }

  useEffect(() => {
    if (profile.role === UserRole.Student) {
      const resume = profile?.professionalDetails?.resumes?.[0]
      const seenAt = profile?.meta?.profileSeenAt

      let seenAtCondition = false

      if (resume?.uploadedAt && seenAt !== undefined) {
        seenAtCondition = toUnix(resume?.uploadedAt) > toUnix(seenAt)
      }

      if (resume && resume.status && seenAtCondition) {
        setResumeStatus(resume?.status)
        setShowCvProgressCard(true)
      }

      if (resume && !isEmpty(resume?.identifier)) {
        if (resume.status === ApprenticeResumeStatus.IN_PROGRESS) {
          const channels = pusher.allChannels()
          const channelNames = channels.map(item => item.name)
          const pusherChannel = channelNames.includes(profile._id)
            ? pusher.channel(profile._id)
            : pusher.subscribe(profile._id)

          pusherChannel.bind(RESUME_PROGRESS.RESUME_PROGRESS, ({ status }) => {
            setResumeStatus(status)
            setUpdate(true)
          })
        }
      }

      return () => {
        if (
          resume?.status &&
          resume?.status !== ApprenticeResumeStatus.IN_PROGRESS
        )
          pusher.unsubscribe(profile._id)
      }
    }
  }, [profile])

  const renderCvProgress = useMemo(() => {
    const progressData = {
      title: '',
      description: '',
      percent: 0,
      hrefUrl: null,
      progressBarColor: ''
    }
    const resume = profile?.professionalDetails?.resumes?.[0]
    const are = pluralize('is', resume?.partialErrorsCount)
    const elements = pluralize('element', resume?.partialErrorsCount)
    const description = replace(
      label('CV_STATUS_PARTIAL_DESC'),
      '{itemNumbers}',
      resume?.partialErrorsCount
    )

    if (resumeStatus) {
      switch (resumeStatus) {
        case ApprenticeResumeStatus.COMPLETE:
          progressData.title = label('CV_STATUS_SUCCESS_TITLE')
          progressData.description = label('CV_STATUS_SUCCESS_DESC')
          progressData.percent = 100
          progressData.hrefUrl = '/profile'
          progressData.progressBarColor = 'bg-green-500'
          break
        case ApprenticeResumeStatus.PARTIAL:
          progressData.title = label('CV_STATUS_PARTIAL_TITLE')
          progressData.description = replace(
            replace(description, '{are}', are),
            '{elements}',
            elements
          )
          progressData.percent = 100
          progressData.hrefUrl = '/profile'
          progressData.progressBarColor = 'bg-yellow-500'
          break
        case ApprenticeResumeStatus.FAILED:
          progressData.title = label('CV_STATUS_FAILED_TITLE')
          progressData.description = label('CV_STATUS_FAILED_DESC')
          progressData.percent = 100
          progressData.hrefUrl = '/profile'
          progressData.progressBarColor = 'bg-red-500'
          break
        case ApprenticeResumeStatus.IN_PROGRESS:
          progressData.title = label('CV_STATUS_IN_PROGRESS_TITLE')
          progressData.description = label('CV_STATUS_IN_PROGRESS_DESC')
          progressData.percent = 50
          progressData.hrefUrl = null
          progressData.progressBarColor = 'bg-blue-500'
          break
      }
    }
    // this should be a separate component
    return (
      <div className="border p-3 m-3 rounded-md flex-col space-y-2 text-white bg-[#374151] hidden md:block">
        <div className="flex-col pb-3 space-y-2">
          <p className="text-sm font-bold whitespace-nowrap ">
            {progressData.title}
          </p>
          <div className="gap-2 text-xs font-normal">
            <p>
              {progressData.description}{' '}
              {progressData.hrefUrl && (
                <Link href={progressData.hrefUrl}>
                  <span className="underline cursor-pointer">here.</span>
                </Link>
              )}
            </p>
          </div>
        </div>
        <span
          role="progressbar"
          aria-labelledby="ProgressLabel"
          aria-valuenow={progressData.percent}
          className={clsx(
            'block rounded-full bg-gray-500',
            progressData.percent < 100 ? 'animate-pulse' : ''
          )}
        >
          <span
            className={`block h-2 rounded-full ${progressData.progressBarColor}`}
            style={{ width: `${progressData.percent}%` }}
          ></span>
        </span>
      </div>
    )
  }, [resumeStatus])

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto font-inter max-h-dvh">
        <div className="flex flex-col mb-8 overflow-auto font-inter">
          <ShouldRender
            check={user?.role === UserRole.Professional && !isVfsServiceEnable}
          >
            <ButtonAntd
              onClick={() => router.push('/corporate/jobs/create-job')}
              icon={<SparklesIcon className="w-5 text-white rotate-180" />}
              className="flex items-center justify-center h-10 gap-2 mx-4 my-3 text-base font-medium text-white border-0 rounded-md bg-qureosPrimary md:mx-auto md:w-56"
            >
              Source Candidates
            </ButtonAntd>
          </ShouldRender>
          {map(
            filter(
              filter(
                filter(pages, { section: SidebarSectionsEnum.OTHER_SECTION }),
                filterPages
              ),
              filterBySubscription
            ),
            page => (
              <Link
                href={page.link ? page.link : page.url}
                key={kebabCase(`sidebar-route-${page.pathname}`)}
                passHref
              >
                <a
                  className={clsx(
                    'border-l-4 group w-full px-3 py-3 flex gap-2 items-center ',
                    isActive(router?.pathname, page.pathname, user)
                      ? 'bg-qureosPrimaryLight border-l-4 border-qureosPrimary text-gray-700'
                      : 'border-transparent text-gray-400 hover:border-qureosPrimary hover:bg-qureosPrimaryLight'
                  )}
                  href={page?.link}
                  onClick={() => handleClick({ page })}
                  target={page?.link ? '_blank' : ''}
                  rel="noreferrer noopener"
                >
                  {isActive(router?.pathname, page.pathname, user)
                    ? isMobile
                      ? page.icon.activeMobile
                      : page.icon.active
                    : isMobile
                      ? page.icon.normalMobile
                      : page.icon.normal}
                  {showLabel && (
                    <div className="flex items-center justify-between w-full">
                      <div
                        className={clsx(
                          labelClass,
                          isActive(router?.pathname, page.pathname, user)
                            ? 'text-gray-700'
                            : 'text-gray-600',
                          'py-1 font-medium'
                        )}
                      >
                        {page.label}
                      </div>
                      <div>
                        {page.showNotificationBadge &&
                        uniqueJobCountWithUpdates > 0 ? (
                          <span className="px-2 py-1 font-medium text-white bg-red-500 rounded-2xl">
                            +{uniqueJobCountWithUpdates}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}
                </a>
              </Link>
            )
          )}
        </div>
      </div>
      <ShouldRender check={user?.role === UserRole.Professional}>
        <ShouldRender
          check={
            process.env.APP_ENV === 'production' && !user?.defaultCompany?.isVfs
          }
        >
          <a
            className="flex items-center justify-center gap-2 py-2 mx-4 my-2 text-base font-medium text-white border-0 rounded-md bg-qureosPrimary md:mx-auto md:w-56"
            onClick={() => {
              // @ts-ignore
              window?.Calendly?.initPopupWidget({
                url: 'https://calendly.com/alex-qureos/iris-demo-20-min?primary_color=0f9f6e'
              })
              return false
            }}
          >
            Talk to sales
          </a>
        </ShouldRender>
        <ShouldRender check={!isEmpty(usage)}>
          <ShouldRender
            check={
              (usage?.planId ||
                (!usage?.planId && usage?.remainingCredits > 0)) &&
              !isVfsServiceEnable
            }
          >
            <Link href="/settings?activeTab=2">
              <a>
                <div className="flex flex-wrap items-center justify-between p-3 m-3 rounded-md cursor-pointer bg-green-50 hover:bg-green-100">
                  <div className="flex items-center gap-2">
                    <p className="flex items-center justify-center w-auto h-auto p-1 text-xs text-white rounded-full bg-qureosPrimary">
                      {usage?.remainingCredits}
                    </p>
                    <p className="font-medium text-qureosPrimary ">
                      Available Credits
                    </p>
                  </div>
                  <ArrowRightIcon className="w-3 text-qureosPrimary" />
                </div>
              </a>
            </Link>
          </ShouldRender>
          <ShouldRender
            check={
              !usage?.planId &&
              usage?.remainingCredits <= 0 &&
              !isVfsServiceEnable
            }
          >
            <Link href="/settings?activeTab=2">
              <a>
                <div className="flex p-3 m-3 rounded-md cursor-pointer bg-green-50 hover:bg-green-100">
                  <div className="flex items-center justify-center w-full gap-2">
                    <p>
                      <SparklesIcon className="flex-shrink-0 w-5 h-5 rotate-180 text-qureosPrimary" />
                    </p>

                    <p className="font-medium text-qureosPrimary">Upgrade</p>
                  </div>
                </div>
              </a>
            </Link>
          </ShouldRender>
        </ShouldRender>
      </ShouldRender>
      {user?.role === UserRole.Student && (
        <Fragment>
          <ShouldRender check={showCvProgressCard}>
            {renderCvProgress}
          </ShouldRender>
          <ShouldRender check={!showCvProgressCard && !showCard}>
            <div className="border p-3 m-3 rounded-md flex-col space-y-2 text-white bg-[#374151] hidden md:block">
              <div className="flex-col pb-3 space-y-2">
                <p className="text-sm font-bold">Get job recommendations</p>
                <div className="flex flex-col gap-2 text-xs font-normal">
                  <span>
                    You&apos;ll receive job opportunities from employers based
                    on your profile.
                  </span>
                  <span>Make your profile stand out!</span>
                </div>
              </div>
              <Button
                theme="normal"
                className="items-center text-sm font-semibold"
                rounded="normal"
                width="full"
                onClick={() => {
                  segmentTrackCallback(SegmentEventType.SIDEBAR_PROFILE_NUDGE, {
                    dateTime: new Date(),
                    fromUrl: router.asPath,
                    redirectUrl: '/profile',
                    email: user?.email,
                    userId: user._id,
                    firstName: user?.firstName,
                    lastName: user?.lastName
                  })
                  router.push('/profile')
                }}
              >
                Complete Profile
              </Button>
            </div>
          </ShouldRender>
        </Fragment>
      )}
      <div className="m-0 border-t border-gray-200" />
      <div className="flex items-center justify-between px-4 py-4 font-inter hover:cursor-pointer ">
        <div className="flex gap-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              segmentTrackCallback(SegmentEventType.PROFILE_PIC_TO_PROFILE, {
                dateTime: new Date(),
                fromUrl: router.asPath,
                redirectUrl: '/profile',
                email: user?.email,
                userId: user._id,
                firstName: user?.firstName,
                lastName: user?.lastName
              })
              router.push('/profile')
            }}
          >
            <Avatar size={50} src={user?.photo} alt="profile-picture">
              {getUserInitials(`${user?.firstName} ${user?.lastName}`)}
            </Avatar>
          </div>
          <div className="grid">
            <span className="font-medium text-gray-900 w-full max-w-[144px] overflow-hidden whitespace-nowrap text-ellipsis">{`${user?.firstName} ${user?.lastName}`}</span>
            <span className="text-gray-500">
              {user?.role === UserRole.Mentor && 'Mentor Profile'}
              {user?.role === UserRole.Student && 'Candidate Profile'}
              {user?.role === UserRole.Professional &&
                !isVfsServiceEnable &&
                'Corporate Profile'}
              {user?.role === UserRole.Professional &&
                isVfsServiceEnable &&
                'Business Profile'}
            </span>
          </div>
        </div>
        <Dropdown placement="topCenter" overlay={menu} trigger={['click']}>
          <EllipsisVerticalIcon
            width={26}
            height={26}
            className="text-gray-400 cursor-pointer"
          />
        </Dropdown>
      </div>
    </>
  )
}

export default ApprenticeSideMenu
